import { Card, CardContent, styled, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'
import { ReactComponent as PhoneV2 } from './Assets/phoneV2.svg'
import { ReactComponent as MailV2 } from './Assets/mailV2.svg'
import { ReactComponent as Facebook } from './Assets/facebook.svg'
import { Box } from '@mui/system'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { get } from 'lodash'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const QuestionCardV2 = observer(props => { 
    const phone = get(StoreFeelGreat, 'contents.contact.phone', '')
    const email = get(StoreFeelGreat, 'contents.contact.email', '')
    const facebook = 'Unicity turkiye'
    const { width } = useSizeWindow()
    const translate = useTranslate()
    return (
        <Card>
            <CardContent style={{ padding: width < 600 ? '30px 20px' : 30 }}>
                <Typography
                    variant="caption"
                    sx={{
                        marginBottom: '8px',
                        lineHeight: '32px'
                    }}>
                    <T>if_you_have_any_question</T>,
                </Typography>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: '25px',
                        lineHeight: '32px'
                    }}>
                    <T>please_contact_us_via_our</T>
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                            md: 'column'
                        },
                        width: '100%',
                        flex: '1',
                        gap: '16px',
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                            width: {
                                xs: '100%',
                                sm: '50%',
                                md: '100%'
                            }
                        }}>
                        <PhoneV2 />
                        <Typography
                            variant="caption"
                            sx={{
                                color: '#003764',
                                cursor: 'pointer'
                            }}
                            onClick={() => window.open(`tel:${phone}`, '_self')}>
                            {phone}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                            width: {
                                xs: '100%',
                                sm: '50%',
                                md: '100%'
                            }
                        }}>
                        <MailV2 />
                        <Typography
                            variant="caption"
                            sx={{
                                color: '#003764',
                                cursor: 'pointer'
                            }}
                            onClick={() => window.open(`mailto:${email}`, '_self')}>
                            {email}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                            width: {
                                xs: '100%',
                                sm: '50%',
                                md: '100%'
                            }
                        }}>
                        <Facebook />
                        <Typography
                            variant="caption"
                            sx={{
                                color: '#003764',
                            }}
                        >
                            {facebook}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
})

export default QuestionCardV2
