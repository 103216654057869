import { makeAutoObservable, runInAction } from 'mobx'

class StorePaymentStep {
    PaymentSteps = null
    PaymentDone = false
    openModalBankWire = false
    errors = []

    GetPaymentSteps() {
        return this.PaymentSteps
    }

    SetPaymentStep(key, value) {
        this.PaymentSteps[key] = value
    }

    SetPaymentSteps(steps) {
        runInAction(() => {
            this.PaymentSteps = steps
        })
    }

    SetOpenModalBankWire(value) {
        runInAction(() => {
            this.openModalBankWire = value
        })
    }

    GetOpenModalBankWire() {
        return this.openModalBankWire
    }

    PaymentErrors() {
        return this.errors
    }

    SetErrors(value) {
        runInAction(() => {
            this.errors = value
        })
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new StorePaymentStep()
