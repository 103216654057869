import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import Payment from '../Payment'
import { PaymentAPIs } from '../PaymentServices/PaymentAPI'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import storeCredit from 'GlobalStores/StoreCredit'
import States from 'Components/Elements/Forms/StatesModal/Country/TurkiyeStates.json'
import Address from 'Components/Elements/Forms/StatesModal/Country/TurkiyeAddress.json'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

class Turkiye {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']
    shippingMethod = 'delivery'
    autoAddressKeyToForm = {
        street: 'address',
        locality: 'district_selection',
        administrative_area: 'city',
        postal_code: 'zip_code'
    }

    chainAddress = {
        isChainAddress: true,
        groupAddress: ['city_selection', 'district_selection', 'neighborhood_selection']
    }

    uuid = ''

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            address: '',
            city: '',
            zip: '',
            email: '',
            phone: '',
            password: ''
            // password1: '',
            // password2: ''
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            address: '',
            city: '',
            zip: '',
            email: '',
            phone: '',
            password1: '',
            password2: ''
        },
        shop: {
            uuid: this.GetUuid(),
            address: '',
            city: '',
            zip: '',
            email: ''
        }
    }

    formShop = {
        email: '',
        phone: '',
        street_address: '',
        city: '',
        zip_code: ''
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        address: '',
        city: '',
        zip: '',
        period: ''
    }

    ExcludedFieldsShippingIfCreateAccount = ['fullName']

    ExcludedFieldsShipping = []

    mapFormKeyToFormatKey = {
        street_address: 'address',
        city_selection: 'city',
        district_selection: 'district',
        fullName: 'fullname',
        zip_code: 'zip',
        neighborhood_selection: 'neighborhood'
    }

    ArrangeCreateCheckoutForm = [
        'firstname',
        'lastname',
        'email',
        'password',
        'phone',
        'country',
        'address',
        'citizenship_id',
        'city_selection',
        'district_selection',
        'neighborhood_selection',
        'zip_code'
    ]

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        {
            caption: 'purchase',
            link: '/checkout/payment',
            paramsRequire: true
        }
    ]

    shippingMethods = [
        {
            id: 1,
            api_value: 'delivery',
            title: 'standard_delivery',
            dict_key: '',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Bank wire',
                    dict_key: 'bankwire',
                    for_status: [],
                    value: 'bankwire',
                    descripion: {
                        title: '',
                        dict_key: ''
                    },
                    icon: ''
                },
                {
                    id: 2,
                    title: 'Credit card',
                    dict_key: 'moka_creditcard',
                    for_status: [],
                    value: 'creditcard',
                    descripion: {
                        title: '',
                        dict_key: ''
                    },
                    icon: 'creditcard-icon'
                },
            ]
        }
        // {
        //     id: 1,
        //     api_value: 'pickup',
        //     title: 'Pickup, Casablanca Office',
        //     dict_key: 'pickup_casablanca_office',
        //     for_status: [],
        //     value: '',
        //     options: [
        //         {
        //             id: 1,
        //             title: 'Credit card',
        //             dict_key: 'creditcard',
        //             for_status: [],
        //             value: ''
        //         }
        //     ]
        // },
        // {
        //     id: 2,
        //     api_value: 'ctm',
        //     title: 'CTM, Delivery to Centers',
        //     dict_key: 'ctm_delivery_to_centers',
        //     for_status: [],
        //     options: [],
        //     value: ''
        // },
        // {
        //     id: 3,
        //     api_value: 'homedelivery',
        //     title: 'Home Delivery',
        //     dict_key: 'home_delivery',
        //     for_status: [],
        //     value: '',
        //     options: [
        //         {
        //             id: 1,
        //             title: 'Credit card',
        //             dict_key: 'creditcard',
        //             for_status: [],
        //             value: ''
        //         },
        //         {
        //             id: 2,
        //             title: 'Cash on Delivery',
        //             dict_key: 'cash_on_delivery',
        //             descripion: {
        //                 title: 'Pay when you receive',
        //                 dict_key: 'pay_when_you_receive'
        //             },
        //             for_status: ['M'],
        //         },
        //         {
        //             id: 3,
        //             title: 'Bank wire',
        //             dict_key: 'bankwire',
        //             for_status: [],
        //             value: 'bankwire',
        //             descripion: {
        //                 title: '',
        //                 dict_key: ''
        //             },
        //             icon: 'creditcard-icon'
        //         }
        //     ]
        // }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: true,
            dict_key: 'shipping',
            fromOrderCal: true
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner'
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice'
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay'
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: {}
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {}
        },
        {
            nameComponent: 'frequentlyAskedQuestions',
            titleContent: 'questions',
            style: {
                backgroundColor: '#F6F9FC'
            }
        }
    ]

    paymentMethod = ''

    StatesList() {
        let items = []
        States.map(raw => {
            items.push({
                text: raw.abbreviation,
                value: raw.abbreviation,
                html: `<span>${raw.name}</span>`
            })
        })
        return items
    }

    AddressOptionList(key = '', fn) {
        let items = { city: [], district: [], neighborhood: [] }

        const cityValue = get(
            (fn.GetCheckoutForms() || []).find(item => item.key === 'city_selection'),
            'value'
        )
        const districtValue = get(
            (fn.GetCheckoutForms() || []).find(item => item.key === 'district_selection'),
            'value'
        )

        // city data
        const city = Object.keys(Address)

        city.map(raw => {
            items = {
                ...items,
                city: [
                    ...items.city,
                    {
                        text: raw,
                        value: raw,
                        html: `<span>${raw}</span>`
                    }
                ]
            }
        })

        if (cityValue) {
            // check
            Object.keys(get(Address, cityValue, {})).map(raw => {
                items = {
                    ...items,
                    district: [
                        ...items.district,
                        {
                            text: raw,
                            value: raw,
                            html: `<span>${raw}</span>`
                        }
                    ]
                }
            })
        } else {
            items = {
                ...items,
                district: []
            }
        }

        if (districtValue) {
            Object.keys(get(Address, [cityValue, districtValue], {})).map(raw => {
                items = {
                    ...items,
                    neighborhood: [
                        ...items.neighborhood,
                        {
                            text: raw,
                            value: raw,
                            html: `<span>${raw}</span>`
                        }
                    ]
                }
            })
        } else {
            items = {
                ...items,
                neighborhood: []
            }
        }

        return items[key.replaceAll('_selection', '')]
    }

    MapAutoAddressToForm(form, autoAddressData) {
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)
        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData.data) {
                    item.value = autoAddressData.data[swappedValuesAsKeys[item.key]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }

            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetPaymentMethod() {
        return this.paymentMethod
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    GetShippingMethod() {
        return this.shippingMethods.find(item => item.api_value === this.shippingMethod)
    }

    GetShippingMethod() {
        return this.shippingMethods
    }

    SetPaymentMethod(value) {
        runInAction(() => {
            this.paymentMethod = value
        })
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, products) {
        try {
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            const allowCreditEnable = get(StoreAuth, 'allowCredit.enable', false)

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, storeCredit.ProductCreditUsed()]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }

            await Pay.PostToHydra()

            // Pay.SendEmail()
            Pay.SendSms()

            await Pay.UpdateToLog()

            const responsePaymentDetail = await PaymentAPIs.GetPaymentDetail(responsePreparePayment.data.data.payment_id)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })
            try {
                TrackingEvents.PurchaseEvent({
                    transaction_id: responsePreparePayment.data.data.payment_id,
                    value: Pay.ushop.total_price,
                    currency: 'AUD',
                    category: Pay.ushop.shipment_purchas_option,
                    items: products
                })
            } catch (e) {
                console.error('TrackingEvents.PurchaseEvent', e)
            }
            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                order_number: responsePaymentDetail.data.order_number
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async SubmitBankwirePayment(uuid, detailPage, products) {
        try {
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            if (!Pay.hydra.transactions) {
                Pay.hydra.transactions = {}
            }

            Pay.hydra.transactions = {
                items: [
                    {
                        amount: 'this.terms.total',
                        type: 'IOU',
                        method: 'BankWire',
                        // methodDetails: {}
                        billToEmail: responsePreparePayment.data.data.hydra.shipToEmail,
                        billToPhone: responsePreparePayment.data.data.hydra.shipToPhone,
                        billToAddress: {
                            country: storeCountry.Country2()
                        }
                    }
                ]
            }

            await Pay.PostToHydra()

            Pay.SendEmailV4()

            await Pay.UpdateToLog()

            const responsePaymentDetail = await PaymentAPIs.GetPaymentDetail(responsePreparePayment.data.data.payment_id)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })
            try {
                TrackingEvents.PurchaseEvent({
                    transaction_id: responsePreparePayment.data.data.payment_id,
                    value: Pay.ushop.total_price,
                    currency: 'TRY',
                    category: Pay.ushop.shipment_purchas_option,
                    items: products
                })
            } catch (e) {
                console.error('TrackingEvents.PurchaseEvent', e)
            }
            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                order_number: responsePaymentDetail.data.order_number
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral, cartItems) {
        try {
            const forms = Object.assign(this.createAccountWithShippingAddress, accForm)
            const returnShippingAddress = {}
            shippingForm.map(item => {
                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value.trim()
                    returnShippingAddress[this.mapFormKeyToFormatKey[item.key]] = item.value.trim()
                } else {
                    forms[item.key] = item.value.trim()
                    returnShippingAddress[item.key] = item.value.trim()
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            const periodResponse = await FeelGreatAPI.GetPeriod('AU')
            forms.period = periodResponse.data.entryPeriod
            forms.uuid = uuid
            forms.shipment_packs = JSON.stringify(cartItems)
            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/TUR/feelGreat/createAccount${
                ServerEnv.ENV === 'DEV' ? '?dev=1' : '?emailDelay=1h'
            }`
            const response = await axios.post(url, forms)

            return {
                ...response.data,
                form: forms
            }
        } catch (e) {
            console.error(e)
            let getData = {}
            if (e.response.data) {
                getData = { ...e.response.data }
            }
            return {
                ...getData,
                success: false
            }
        }
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        makeAutoObservable(this)
    }
}

export default Turkiye
